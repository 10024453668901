// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-board-of-directors-js": () => import("./../../../src/pages/about/board-of-directors.js" /* webpackChunkName: "component---src-pages-about-board-of-directors-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-events-gala-dinner-2024-index-js": () => import("./../../../src/pages/events/gala-dinner-2024/index.js" /* webpackChunkName: "component---src-pages-events-gala-dinner-2024-index-js" */),
  "component---src-pages-events-gala-dinner-2024-update-guest-js": () => import("./../../../src/pages/events/gala-dinner-2024/update-guest.js" /* webpackChunkName: "component---src-pages-events-gala-dinner-2024-update-guest-js" */),
  "component---src-pages-foundation-objectives-js": () => import("./../../../src/pages/foundation-objectives.js" /* webpackChunkName: "component---src-pages-foundation-objectives-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obtaining-funding-funding-request-primary-producer-js": () => import("./../../../src/pages/obtaining-funding/funding-request-primary-producer.js" /* webpackChunkName: "component---src-pages-obtaining-funding-funding-request-primary-producer-js" */),
  "component---src-pages-obtaining-funding-index-js": () => import("./../../../src/pages/obtaining-funding/index.js" /* webpackChunkName: "component---src-pages-obtaining-funding-index-js" */),
  "component---src-pages-report-to-the-community-js": () => import("./../../../src/pages/report-to-the-community.js" /* webpackChunkName: "component---src-pages-report-to-the-community-js" */)
}

